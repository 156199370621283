import React from "react";
import "./Contato.css";
import Logo from "../../assets/imgs/simbolodourado.png";

function Contato() {
  return (
    <div className="contato-container">
      <div className="contato-img-container">
      <h2>Contatos</h2>

        <img src={Logo} alt="logo"></img>
      </div>
      <div className="contato-content">
        <div className="advogadas">
          <span>
            <i class="uil uil-phone"> Número do Escritório</i>
           <p>(84)98693-9180</p> 
          </span>
    
        </div>

        <div className="redes">
          <span>
            <i class="uil uil-at"></i>
            <p>contato.ggmadvocacia@gmail.com</p>
          </span>
          <span>
            <i class="uil uil-instagram"></i>
            <p>ggmadvocacia</p>
          </span>
        </div>

       

        
      </div>
       <span>
          <i class="uil uil-map-marker"></i>
          <p>
          Rua Prefeito Sandoval Cavalcante de Albuquerque, 3640 - Candelária, Natal - RN, 59064-735.          </p>
        </span>
    </div>
  );
}

export default Contato;
