import React from 'react';
import './Mapa.css';
function Mapa() {
  return (
    <div className='mapa-container'>
      <iframe className='mpa'
        title="Localização"
        width="100%"
        height="400"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3969.0532726035835!2d-35.21565665964152!3d-5.84811729415962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7b2ff7d3c22d2eb%3A0xce92580d73be844d!2sRua%20Prefeito%20Sandoval%20Cavalcante%20de%20Albuquerque%2C%203640%20-%20Candel%C3%A1ria%2C%20Natal%20-%20RN%2C%2059064-735!5e0!3m2!1spt-BR!2sbr!4v1714761877025!5m2!1spt-BR!2sbr"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default Mapa;