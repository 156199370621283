import React, { useState } from 'react';
import './Faq.css';

function FAQ() {
  const faqData = [
    {
      question: 'Como faço para calcular minhas férias e décimo terceiro? ',
      answer:
        'Nossa advogada especializada em direito trabalhista pode oferecer orientações precisas sobre como calcular férias e décimo terceiro, levando em consideração diversos fatores, como o salário mensal, o período trabalhado e outros benefícios. Essa consulta pode garantir que seus direitos sejam plenamente reconhecidos.'
    },
    {
      question: 'Quais são os meus direitos em caso de demissão?',
      answer:
        'Ao enfrentar uma situação de demissão, é crucial entender seus direitos conforme a legislação trabalhista. Nós podemos fornecer insights valiosos sobre as verbas rescisórias, o aviso prévio, o FGTS e outras questões importantes relacionadas à demissão, garantindo que você esteja devidamente protegido.'
    },
    {
      question: 'Como posso proteger meus direitos durante um divórcio litigioso?',
      answer:
        'Durante um divórcio litigioso, é crucial contar com o suporte de um advogado especializado em direito de família. Nós trabalhamos para proteger seus interesses e garantir uma resolução justa para questões como divisão de bens, guarda de filhos e pensão alimentícia.'
    },
    {
      question: 'Quais são os passos para realizar um inventário?',
      answer:
        'Para realizar um inventário, é crucial entender os procedimentos legais. Um advogado pode orientar sobre coleta de documentos, abertura do processo, levantamento de bens, partilha entre herdeiros e homologação. Essa assistência garante conformidade com a lei, protegendo seus direitos.'
    },
    {
      question: 'Quais são os requisitos para solicitar a aposentadoria por tempo de serviço?',
      answer:
        'Para solicitar a aposentadoria por tempo de serviço, é essencial ter contribuído para o INSS pelo período necessário, geralmente 35 anos para homens e 30 anos para mulheres. Se você atende a esses requisitos ou tem dúvidas sobre sua elegibilidade, entre em contato conosco para receber orientação personalizada e iniciar o processo de solicitação do seu benefício.'
    },
    {
      question: 'Como posso obter o benefício assistencial ao idoso (BPC/LOAS)?',
      answer:
        'Para garantir o benefício assistencial ao idoso (BPC/LOAS), é necessário ter 65 anos ou mais, não receber outro benefício previdenciário, possuir renda familiar per capita inferior a 1/4 do salário mínimo e estar em situação de vulnerabilidade socioeconômica. Se você se enquadra nesses critérios, entre em contato conosco para iniciar o processo de solicitação do seu benefício. Estamos aqui para ajudar a garantir seus direitos previdenciários.'
    }
    // Adicione mais perguntas e respostas conforme necessário
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-wrapper">
      <div className='faq-container'>
        <h2>Perguntas Frequentes</h2>
        <div className='faq-list'>
          {faqData.map((item, index) => (
            <div key={index} className='faq-item'>
              <div
                className={`faq-question ${activeIndex === index ? 'active' : ''}`}
                onClick={() => handleToggle(index)}
              >
                <div className='question-text'>{item.question}</div>
                <div className='toggle-icon'>{activeIndex === index ? '-' : '+'}</div>
              </div>
              <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
                {item.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
