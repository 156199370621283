import React from "react";
import "./Hero.css";


function Hero() {
  return (
    <div className="hero-wrapper">

      <div className="image-overlay"></div>
      <div className="hero-container hero-section">
        <div className="hero-content text-container">
          <h1>Especialistas em Direito Trabalhista, Civil e Previdenciário</h1>
          <p>
          Enfrentando questões legais complexas? Como especialistas em Direito Trabalhista, Civil e Previdenciário, estamos aqui para fornecer orientação jurídica precisa e soluções adaptadas às suas necessidades individuais. Confie em nossa experiência para encontrar as melhores respostas para você.
          </p>
          
        </div>
      </div>
    </div>
  );
}

export default Hero;
