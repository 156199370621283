// Header.js
import React, { useState } from "react";
import MenuBars from "../../assets/imgs/menu-bars.svg";
import Menu from "./Menu";
import "./Header.css";
import Logo from "../../assets/imgs/logo.png";

const Header = ({ scrollToComponent }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="header-wrapper">
      <div className="header-container">
        <div className="logo">
          <button
            onClick={() => {
              scrollToComponent("HeroRef");
            }}
            alt="home"
          >
            <img className="logo" src={Logo} alt="logo"></img>
          </button>
        </div>

        <nav>
          <button className="nav-btn"
            onClick={() => {
              scrollToComponent("HeroRef");
            }}
          >
            Início
          </button>
          <button className="nav-btn"
            onClick={() => {
              scrollToComponent("ServicosRef");
            }}
          >
            Áreas de atuação
          </button>
          <button className="nav-btn"
            onClick={() => {
              scrollToComponent("SobreRef");
            }}
          >
            Sobre
          </button>
          <button className="nav-btn"
            onClick={() => {
              scrollToComponent("ContatoRef");
            }}
          >
            Contato
          </button>
          
        </nav>

        <button  className="menu-toggle" onClick={toggleSidebar}>
          <img src={MenuBars} alt="Menu Bars" />
        </button>
      </div>
      <Menu
        toggleSidebar={toggleSidebar}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        scrollToComponent={scrollToComponent}
      />
    </div>
  );
};

export default Header;
