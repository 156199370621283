import React from "react";
import "./Diferenciais.css";
;

function DiferenciaisCard() {
  return (
    <div className="diferenciais-card">
      <div className="titulos">
        <h2>Nossos diferenciais</h2>
      </div>
      <ul>
        <li>
          <h3>Abrangência de Serviços</h3>
          <p>
            Oferecemos serviços em direito trabalhista, civil e previdenciário,
            atendendo às diversas necessidades legais de nossos clientes em
            todas as fases de suas vidas pessoais e profissionais.
          </p>
        </li>
        <li>
          <h3>Experiência e Especialização</h3>
          <p>
            Nossa equipe é altamente especializada em direito do trabalho,
            família e sucessões, abordando desde questões complexas como
            reconhecimento de vínculo empregatício até assuntos delicados como
            guarda dos filhos.
          </p>
        </li>
        <li>
          <h3>Atendimento Personalizado</h3>
          <p>
            Priorizamos atendimento personalizado e compreensivo, oferecendo
            soluções jurídicas sob medida. Garantimos uma experiência
            transparente, acessível e eficiente, guiando os clientes por todas
            as etapas do processo com dedicação e profissionalismo.
          </p>
        </li>
      </ul>
    </div>
  );
}

export default DiferenciaisCard;
