import React, { useState } from 'react';
import './ContatoForm.css';
const ContactForm = ({ buttonText, subject, onSubmit }) => {
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showAgradecimento, setShowAgradecimento] = useState(false);
    const [formData, setFormData] = useState({
        nome: '',
        email: '',
        celular: '',
        mensagem: '',
        assunto: subject || '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowForm(false);
        setShowAgradecimento(true);
        try {
            const response = await fetch('https://ggmadvogados.adv.br/api/contato', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                // Se a requisição for bem-sucedida, resete o formulário e mostre a mensagem de agradecimento
                setFormData({
                    nome: '',
                    email: '',
                    celular: '',
                    mensagem: '',
                });

            } else {
                // Se houver um erro na requisição, você pode lidar com isso aqui
                console.error('Erro ao enviar formulário:', response.statusText);
            }
        } catch (error) {
            console.error('Erro ao enviar formulário:', error);
        } finally {
            setLoading(false); // Desativar o estado de carregamento após o envio do formulário
        }
    };


    return (
        <div className='contact-form-container'>
            {loading && <p>Carregando...</p>}
            {showForm && (<>
                <form onSubmit={handleSubmit}>

                    <h4>Formulário para atendimento em {subject}</h4>
                    <label>
                        Nome completo:
                        <input
                            type="text"
                            name="nome"
                            value={formData.nome}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label>
                        Email:
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label>
                        Celular:
                        <input
                            type="tel"
                            name="celular"
                            value={formData.celular}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label>
                        Mensagem:
                        <br></br>
                        <textarea
                            name="mensagem"
                            value={formData.mensagem}
                            onChange={handleInputChange}
                            
                        ></textarea>
                    </label>
                    <button type="submit">Enviar</button>
                </form>
            </> )}

            {showAgradecimento && <>
                <div class="container">
                    <div class="message">
                        <h2>Obrigado por submeter o formulário!</h2>
                            <h4>Responderemos em breve!</h4>
                                <p>Enquanto isso, caso queira um atendimento mais rápido, clique no botão abaixo para falar conosco pelo WhatsApp.</p>
                            </div>
                    <a href="https://wa.me/5584986939180" alt="whatsapp link" target="_blank" rel="noreferrer"class="button">Falar diretamente pelo WhatsApp</a>
                    </div>
            </>}

           {!showAgradecimento && <button className='fechar main-btn' onClick={() => setShowForm(!showForm)}>{!showForm ? buttonText : ("Fechar")}</button>}
        </div>
    );
};

export default ContactForm;
