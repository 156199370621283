import React from 'react';
import './Footer.css';

const Footer = ({ scrollToComponent }) => {
  const main = '/';

  return (
    <div className='footer__container'>
      <div className='footer'>
        <div className='footer__child'>
          <div>
            <h3>SOBRE</h3>
            <p>
            Enfrentando questões em direito trabalhista, civil ou previdenciário? Conte com nossa orientação especializada e soluções personalizadas para suas necessidades específicas.
            </p>
          </div>

          
        </div>


        <div className='footer__child footer__menu'>
          <h3>MENU</h3>

          <button className="main-btn" onClick={() => {
            scrollToComponent("HeroRef");
          }}>Início</button >

          <button className="main-btn"
            onClick={() => {
              scrollToComponent("ServicosRef");
            }}
          >
            Áreas de Atuação
          </button>

          <button className="main-btn"
            onClick={() => {
              scrollToComponent("SobreRef");
            }}
          >
            Sobre
          </button>

          <button className="main-btn"
            onClick={() => {
              scrollToComponent("FaqRef");
            }}
          >
            Perguntas Frequentes
          </button>
          <button className="main-btn" onClick={() => {
            scrollToComponent("ContatoRef");
          }}>Contato</button >
        </div>
      </div>

      <div className='copy__container'>
        <div className='copy'>
          {' '}
          <p>
            Desenvolvido por{' '}
            <a href="https://wa.me/5584996060201" target='_blank' rel="noreferrer">Luana Salmito</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;

