import React from "react";
import "./Servicos.css";
import servicosData from "./servicos.json"; // Certifique-se de criar esse arquivo de estilo se necessário.
import ContactForm from "../ContatoForm";
function ServicosCard() {
  return (
    <div className="servicos-wrapper">
      <div className="servicos-card">
        
        <div className="background">
          <h2 className="h2-diferente">
        Direito civil - Família, Sucessões e Direitos Reais
          </h2>
        </div>
        <div className="card">
          <ul>
            {servicosData.map((servico, index) => (
              <li key={index}>
                <h3>{servico.titulo}</h3>
                {/* <p>{servico.texto}</p> */}
              </li>
            ))}
          </ul>
        </div>

        <ContactForm buttonText={"Clique aqui para atendimento sobre Direito de Família, Sucessões e Direitos Reais"} subject={"Direito Civil"} />
      </div>
    </div>
  );
}

export default ServicosCard;


