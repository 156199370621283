import React from "react";
import servicosData from "./servicos.json"; // Certifique-se de criar esse arquivo de estilo se necessário.

import ContactForm from "../ContatoForm";
function ServicosCard() {
  return (
    <div className="servicos-wrapper">
      <div className="servicos-card">
        <div className="main-title-2">
          <h1 className="h2-blue">Áreas de atuação</h1>
        </div>
        <div className="background">
          <h2 className="h2-amarelo">
            Direito do Trabalho - Empregado e Empresa
          </h2>
        </div>
        <div className="card">
          <ul>
            {servicosData.map((servico, index) => (
              <li key={index}>
                <h3>{servico.titulo}</h3>
                {/* <p>{servico.texto}</p> */}
              </li>
            ))}
          </ul>
        </div>

        <ContactForm buttonText={"Clique aqui para atendimento sobre Direito do Trabalho"} subject={"Direito do Trabalho"} />

      </div>
    </div>
  );
}

export default ServicosCard;
