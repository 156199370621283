import React from "react";
import "./Sobre.css";
import Beatriz from "../../assets/imgs/beatriz.svg";
import Mari from "../../assets/imgs/mari.svg";
import Law from "../../assets/imgs/law.svg";

function Sobre() {
  return (
    <div className="sobre-wrapper">
      <div className="sobre-text">
        <div>
          <div className="txt">

            <img className="img-law" src={Law} alt="" />
            <h2 className="main-title-3">
              Conte conosco para representar seus direitos
            </h2>
            <p className="descricao">
              Nosso escritório se destaca pela excelência e compromisso nos
              serviços oferecidos, tanto na área consultiva quanto contenciosa.
              Priorizamos a individualidade dos clientes, oferecendo atendimento
              personalizado e especializado. Valorizamos a confiança e o
              relacionamento, buscando sempre desenvolver as melhores soluções
              para suas necessidades.
            </p>
          </div>
        </div>
        <div className="sobre-image">
          <img src={Beatriz} alt="Beatriz" />
          <img src={Mari} alt="Mari" />
        </div>
        <div className="oabs">
          <h3>Beatriz Gadelha - OAB/RN 20.884</h3>
          <h3>Mariana Godeiro - OAB/RN 11.249</h3>
          <h3>Miriã Martins - OAB/RN 15.097</h3>
        </div>
      </div>
    </div>
  );
}

export default Sobre;
/* Nosso escritório se destaca pelo compromisso e excelência nos serviços prestados.
                Oferecemos aos nossos clientes um serviço personalizado e especializado, onde todos os casos são minuciosamente acompanhados pela nossa equipe.
                Valorizamos a individualidade de nossos clientes, respeitamos suas escolhas e reconhecemos sua importância, priorizando sempre a confiança e o relacionamento. Atuamos na área consultiva e contenciosa, buscando sempre o desenvolvimento das melhores teses de forma eficaz na solução das questões apresentadas. */
