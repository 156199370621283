import React, { useRef } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import Hero from "./components/hero/Hero.js";
import Diferenciais from "./components/diferenciais/Diferenciais.js";
import FAQ from "./components/faq/Faq.js";
import Civil from "./components/servicos/familia/Servicos.js";
import Mapa from "./components/mapa/Mapa.js";
import Sobre from "./components/sobre/Sobre.js";
import Contato from "./components/contato/Contato.js";
import WhatsAppLogo from "./assets/imgs/whatsBlack.svg";
import Trabalhista from "./components/servicos/trabalhista/Servicos.js";
import "./App.css";
import Previdenciario from "./components/servicos/previdenciario/Servicos.js";
import "./assets/styles/Reset.css";

function App() {
  const HeroRef = useRef();
  const FaqRef = useRef();
  const ContatoRef = useRef();
  const SobreRef = useRef();
  const ServicosRef = useRef();

  const scrollToComponent = (refName) => {
    const refs = {
      HeroRef,
      FaqRef,
      ContatoRef,
      SobreRef,
      ServicosRef,
    };

    const selectedRef = refs[refName];

    if (selectedRef && selectedRef.current) {
      var yOffset;
      if (refName === "HeroRef") {
        yOffset = -180;
      } else {
        yOffset = -120;
      }

      const yCoordinate =
        selectedRef.current.getBoundingClientRect().top +
        window.scrollY +
        yOffset;
      window.scrollTo({ top: yCoordinate, behavior: "smooth" });
    }
  };

  const main = "/";

  return (
    <Router>
      <div className="App">
        <Header scrollToComponent={scrollToComponent} />

        <div class="body-wrapper">
          <div class="body-container">
            <div ref={HeroRef}>
              <Hero />
            </div>

            <div ref={ServicosRef}>
              <Trabalhista />
              <Civil />
              <Previdenciario />
            </div>

            <div ref={SobreRef}>
              <Sobre />
            </div>

            <Diferenciais />

            <div ref={FaqRef}>
              <FAQ />
            </div>

            <div ref={ContatoRef}>
              <Contato />
            </div>

            <Mapa />
          </div>
        </div>

        <Footer scrollToComponent={scrollToComponent} />
      </div>
    </Router>
  );
}

export default App;
